import React from 'react';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import UserTotal from '~/containers/UserTotal';

const AppWelcome = withI18next()(({ t, title, usePrimaryColor }) => {
  return (
    <div className={`app-welcome ${usePrimaryColor && Config.serviceType}`}>
      <h1>{title || t('welcome_to_ac')}</h1>
      <div className="country-user-infor">
        <UserTotal />
      </div>
      <style jsx global>
        {`
          .app-welcome {
            text-align: center;
            margin-bottom: 28px;
            color: #fff;
          }
          .app-welcome.illust_ac {
            color: #d73a4e;
          }
          .app-welcome h1,
          .app-welcome h2 {
            margin: 0;
          }
          .app-welcome h1 {
            font-size: 40px;
            font-weight: 600;
          }
          .app-welcome h2 {
            font-size: 25px;
            font-weight: 400;
            margin-top: 14px;
          }
          .app-welcome .country-user-infor {
            font-size: 25px;
            font-weight: 400;
            margin-top: 14px;
          }
        `}
      </style>
    </div>
  );
});

export default AppWelcome;

import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { END } from 'redux-saga';
import Head from 'next/head';
import nextI18nextConfig from '~/../next-i18next.config';
import securePage, { securePageServerSideContext } from '~/../hocs/securePage';
import Layout from '~/containers/Layout';
import LoginContainer from '~/containers/LoginPage';
import config, { keyConfig } from '~/config';
import { wrapper } from '~/store';
import { getDisplayName } from '~/utils/file-helper';
import { throw404 } from '~/utils/redirect';

const roles = config.getRoles();

@securePage([roles.GUEST])
class LoginPage extends React.Component {
  // static async getInitialProps({ req, query, params }) {
  //   if (req) {
  //     return { query: req.query, params: req.params };
  //   }
  //   return { query, params };
  // }

  render() {
    const { query, t, lng } = this.props;
    if (!config.isCreatorAvailable(lng)) {
      return throw404();
    }

    return (
      <Layout>
        <Head>
          <title>{t(keyConfig.get('title_top_page'))}</title>
        </Head>
        <LoginContainer query={query} />
      </Layout>
    );
  }
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (ctx) => {
    const i18nProps = await serverSideTranslations(
      ctx.locale || process.env.LANGUAGE,
      ['common', 'component-FreeAndPremiumCompareTable'],
      nextI18nextConfig,
    );

    const {
      token,
      profile,
      accountType,
      isAuthenticated,
      currentLanguage,
      sessionId,
      ssoToken,
      req,
      query,
    } = await securePageServerSideContext({
      ...ctx,
      store,
      pageName: getDisplayName(LoginPage),
      roles: [roles.GUEST],
    });

    // end the saga
    store.dispatch(END);
    await store.sagaTask.toPromise();

    return {
      props: {
        ...i18nProps,
        token,
        profile,
        accountType,
        isAuthenticated,
        currentLanguage,
        sessionId,
        ssoToken,
        query,
      },
    };
  },
);

export default LoginPage;

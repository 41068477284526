import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loginWithEmail } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectProfile } from '../AuthProvider/selectors';
import { makeSelectAccountType } from '../AccountTypeProvider/selectors';
import { makeSelectCurrentLanguage } from '../Language/selectors';
import { changeAccountType } from '../AccountTypeProvider/actions';
import AppWelcome from '~/components/AppWelcome';
import Link from '~/components/I18nLink';
import LoginForm from '~/components/LoginForm';
import Config from '~/config';
import withI18next from '~/../hocs/_withI18next';
import injectReducer from '~/../hocs/injectReducer';
import injectSaga from '~/../hocs/injectSaga';
import { creatorLanguage } from '~/../hocs/creatorLanguage';
import Cookies from '~/services/Cookies';
import { USER_ROLES } from '~/utils/constants';
import I18nRouter from '~/components/I18nRouter';

@injectReducer({ key: 'login', reducer })
@injectSaga({ key: 'login', saga })
@withI18next()
@creatorLanguage()
class LoginContainer extends React.Component {
  componentDidMount() {
    Cookies.set('accountType', USER_ROLES.CREATOR);
  }

  getLinkRedirect() {
    const { query, currentLanguage } = this.props;
    return query && query.redirect
      ? `/${currentLanguage}/${query.redirect}`
      : `/${currentLanguage}`;
  }

  handleTypeAccount = (accountType) => {
    this.props.changeAccountType(accountType);
  };

  onSubmit = (values) =>
    new Promise((resolve, reject) => {
      console.log('000000000000000000', values);
      this.props.loginWithEmail({
        values,
        resolve,
        reject,
        currentLanguage: this.props.currentLanguage,
      });
    }).then(({ token, profile }) => {
      let redirectURL = '/my-page/creator-info';
      if (this.props.currentLanguage !== 'en') {
        redirectURL = `${redirectURL}`;
      }
      I18nRouter.push(redirectURL, redirectURL, { query: { token } });
    });

  render() {
    const { t, lng, accountType, query, checkIsShow } = this.props;

    return (
      <div className={`login-page ac-auth-bg-${Config.serviceType}`}>
        <AppWelcome
          usePrimaryColor={Config.serviceType === 'illust_ac'}
          typePage="LOGIN"
        />
        <div className="ac-dialog">
          <div className={`dialog-header ${Config.serviceType}`}>
            <FontAwesomeIcon
              icon={faSignInAlt}
              className="dialog-header-icon"
            />
            {t(Config.getPrefixContent('creator_login_title'))}
          </div>
          <div className="dialog-body">
            <div className="login-section">
              <LoginForm onSubmit={this.onSubmit} {...this.props} t={t} />
              {/* <div className="text-center" style={{ paddingTop: 15 }}>
                <small>
                  {t('signup_as_creator')}{' '}
                  <Link href="/creator-registration">
                    <a
                      className={`ac-link ${Config.serviceType}_color-ac-primary`}
                    >
                      {t('here')}
                    </a>
                  </Link>
                </small>
              </div> */}
            </div>
          </div>
        </div>
        <style jsx>
          {`
            .login-page {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 120px 0 250px 0;
            }
            .login-page .social-section {
              background: #fff;
              text-align: center;
            }
            .login-page .login-section {
              padding: 28px;
            }
            @media (max-width: 767px) {
              .login-page .ac-dialog {
                width: calc(100% - 20px);
                margin-left: 10px;
                margin-right: 10px;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  currentLanguage: makeSelectCurrentLanguage(),
  accountType: makeSelectAccountType(),
});
const mapDispatchToProps = (dispatch) => ({
  loginWithEmail: (credential) => dispatch(loginWithEmail(credential)),
  changeAccountType: (accountType) => dispatch(changeAccountType(accountType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

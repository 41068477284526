import { createSelector } from 'reselect';

const selectLogin = (state) => state.login;

const makeSelectIsSubmitting = () =>
  createSelector(selectLogin, (subState) => subState.isSubmitting);

const makeSelectError = () =>
  createSelector(selectLogin, (subState) => subState.error);

export { selectLogin, makeSelectIsSubmitting, makeSelectError };

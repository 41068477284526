import {
  LOGIN_EMAIL,
} from './constants';

export function loginWithEmail(credential) {
  return {
    type: LOGIN_EMAIL,
    credential,
  };
}

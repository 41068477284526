import {
  LOGIN_EMAIL_ERROR,
  LOGIN_EMAIL_REQUEST,
  LOGIN_EMAIL_SUCCESS,
} from './constants';

export const initialState = {
  isSubmitting: false,
  error: false,
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_EMAIL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case LOGIN_EMAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case LOGIN_EMAIL_ERROR:
      return { ...state, error: action.error, isSubmitting: false };
    default:
      return state;
  }
}

export default loginReducer;

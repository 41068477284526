import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../FormInput';
import Link from '~/components/I18nLink';
import * as validateService from '~/services/validate';
import Config from '~/config';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Mail address required';
  } else if (!validateService.validateEmail(values.email)) {
    errors.email = 'Mail address invalid';
  }

  if (!values.password) {
    errors.password = 'Password required';
  } else if (values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters long';
  } else if (values.password.length > 15) {
    errors.password = 'Password should be less than 15 characters';
  }

  return errors;
};

const styles = {
  iconLock: {
    width: 22,
    marginRight: 7,
  },
};

const LoginForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onForgotPassword,
    t,
  } = props;
  return (
    <form onSubmit={handleSubmit} className="login-form">
      <Field
        name="email"
        type="email"
        require
        component={FormInput}
        placeholder={t('Mail address')}
      />
      <Field
        name="password"
        type="password"
        require
        component={FormInput}
        placeholder={t('Password')}
      />
      <div className="text-right" style={{ paddingBottom: 15 }}>
        {onForgotPassword ? (
          <a onClick={onForgotPassword}>
            <small className={`${Config.serviceType}_color-ac-primary`}>
              {t('Forgot your password?')}
            </small>
          </a>
        ) : (
          <Link href="/forgot-password">
            <a>
              <small className={`${Config.serviceType}_color-ac-primary`}>
                {t('Forgot your password?')}
              </small>
            </a>
          </Link>
        )}
      </div>
      <button
        type="submit"
        className={`btn btn-ac-primary btn-${Config.serviceType} btn-block btn-dialog-action`}
        disabled={pristine || submitting}
      >
        {submitting && (
          <FontAwesomeIcon icon={faSpinner} style={styles.iconLock} pulse />
        )}
        {t('login')}
      </button>
      <style jsx>
        {`
          .login-form a {
            cursor: pointer;
            color: #ffa823;
            user-select: none;
          }
          .login-form a:hover {
            color: #ffa823;
            text-decoration: none;
          }

          .login-form .btn-login {
            height: 45px;
            font-size: 16px;
          }
        `}
      </style>
    </form>
  );
};

export default reduxForm({
  form: 'loginForm',
  validate,
})(LoginForm);

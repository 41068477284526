import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import Router from 'next/router';
import { i18n } from 'next-i18next';
import {
  LOGIN_EMAIL,
  LOGIN_EMAIL_ERROR,
  LOGIN_EMAIL_REQUEST,
  LOGIN_EMAIL_SUCCESS,
} from './constants';
import { makeSelectIsSubmitting } from './selectors';
import { LOGIN } from '../AuthProvider/constants';
import { CREATOR_ACCOUNT } from '../AccountTypeProvider/constants';
import { showError } from '~/containers/CustomErrorMessage/actions';
import { ERROR_TYPE } from '~/utils/errorHandler';
import * as authService from '~/services/auth';
import Cookies from '~/services/Cookies';
import { USER_ROLES } from '~/utils/constants';

function* loginWithEmail(params) {
  const { values, resolve, reject, path, currentLanguage } = params.credential;
  try {
    const isSubmitting = yield select(makeSelectIsSubmitting());
    if (isSubmitting) return;

    yield put({
      type: LOGIN_EMAIL_REQUEST,
    });
    const response = yield call(authService.loginWithEmail, {
      email: values.email,
      password: values.password,
      accountType: CREATOR_ACCOUNT,
      currentLanguage,
    });
    console.log('loginWithEmaillllllllll', response);
    Cookies.set('accountType', USER_ROLES.CREATOR);
    Cookies.set('token', response.token);
    Cookies.set('profile', response.profile);
    Cookies.set('accountType', USER_ROLES.CREATOR);

    yield put({
      type: LOGIN_EMAIL_SUCCESS,
    });
    yield put({
      type: LOGIN,
      token: response.token,
      profile: response.profile,
    });
    resolve(response);
  } catch (error) {
    if (error.response) {
      const { message } = error.response.data;
      if (error.response.status === 400) {
        if (message.type === ERROR_TYPE.INVALID_PASSWORD) {
          return reject(
            new SubmissionError({
              password: 'Password invalid',
            }),
          );
        }
        if (message.type === ERROR_TYPE.INVALID_EMAIL) {
          return reject(
            new SubmissionError({
              email: 'Email invalid',
            }),
          );
        }
      }
      if (error.response.status === 403) {
        if (message.type === ERROR_TYPE.ACC_IS_NOT_ACTIVE) {
          return reject(
            new SubmissionError({
              password: 'Your account is not activated yet',
            }),
          );
        }
      }
      yield put(
        showError({
          error,
          title: i18n.t('login_failed'),
        }),
      );
      reject(error);
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* loginSaga() {
  yield takeLatest(LOGIN_EMAIL, loginWithEmail);
}
